// import React from 'react';
// import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
// import clsx from 'clsx';
import {
  // AppBar,
  // Box,
  // Hidden,
  IconButton,
  // Toolbar,
  // makeStyles,
  SvgIcon
} from '@material-ui/core';
import { Menu as MenuIcon } from 'react-feather';
// import Logo from 'src/components/Logo';
import { THEMES } from 'src/constants';
// import Account from './Account';
// import Contacts from './Contacts';
// import Notifications from './Notifications';
// import Search from './Search';
// import Settings from './Settings';
import React, { useState, useEffect } from 'react'
import { Link as RouterLink } from 'react-router-dom';
// import clsx from 'clsx';
import OrangeHollowButton from 'src/components/OrangeHollowButton';

// import PropTypes from 'prop-types';
import {
  AppBar,
  Box,
  // Button,
  // Divider,
  Toolbar,
  Hidden,
  // Typography,
  Link,
  makeStyles
} from '@material-ui/core';
// import { APP_VERSION } from 'src/constants';

// import {
//   // Twitter as TwitterIcon,
//   // Calendar as CalendarIcon,
//   // Linkedin as LinkedInIcon
// } from 'react-feather';
// import { useHistory } from 'react-router-dom';

import Logo from 'src/components/BridgeLogo';

// import firebase from 'src/lib/firebase';

// const remoteConfig = firebase.remoteConfig();
// remoteConfig.settings.minimumFetchIntervalMillis = 300000;

// import { initializeApp } from 'firebase-admin/app';
// import { getRemoteConfig } from "firebase/remote-config";
// const remoteConfig = getRemoteConfig();

const useStyles = makeStyles(theme => ({
  root: {
    zIndex: theme.zIndex.drawer + 100,
    ...(theme.name === THEMES.LIGHT
      ? {
          boxShadow: 'none',
          backgroundColor: theme.palette.primary.main
        }
      : {}),
    ...(theme.name === THEMES.ONE_DARK
      ? {
          backgroundColor: theme.palette.background.default
        }
      : {}),
    ...(theme.name === THEMES.OSNEY
      ? {
        boxShadow: 'none',
        // backgroundColor: 'transparent !important',
        backgroundColor: 'transparent',
        // backgroundColor: theme.palette.primary.main
      }
      : {})
  },
  toolbar: {
    minHeight: 100
  },
  icon: {
    padding: 25,
    margin: 25
  },
  link: {
    color: 'white',
    paddingRight: '12px'
  },
  version: {
    paddingLeft: '12px'
  },
  divider: {
    borderRight: `2px solid ${theme.palette.divider}`
  }
}));

const TopBar = ({ className, onMobileNavOpen, ...rest }) => {
  const classes = useStyles();

  // function handleClick() {
  //   window.location.href = 'https://www.linkedin.com/company/osneycapital/';
  // }
  // function handleTwitterClick() {
  //   window.location.href = 'https://twitter.com/osneycapital';
  // }

  const [ backgroundColor, setBackgroundColor ] = useState('#002147');

  const listenScrollEvent = (event) => {
    if (window.scrollY < 193) {
      return setBackgroundColor('#002147');
    } else if (window.scrollY > 190) {
      return setBackgroundColor('#002147');

      // alert('trigger');
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', listenScrollEvent);

    return () =>
      window.removeEventListener('scroll', listenScrollEvent);
  }, []);


  return (
    // <AppBar className={clsx(classes.root, className)} {...rest}>
      <AppBar style={{zIndex: '200', boxShadow: 'none', backgroundColor: backgroundColor}}>
      <Toolbar className={classes.toolbar}>
        {/*<Hidden mdDown>*/}
        <RouterLink to="/">
          <Logo style={{ maxHeight: '50px', maxWidth: '100%' }} />
        </RouterLink>
        {/*</Hidden>*/}
        {/*<Hidden mdDown>
          <Typography
            variant="caption"
            color="white"
            style={{ marginLeft: '15' }}
          >
            <div className={classes.version}>Version {APP_VERSION}</div>
          </Typography>
        </Hidden>*/}
        <Box ml={2} flexGrow={1} />
        <Hidden xsDown>
          
           <Link
            className={classes.link}
            // color="white"
            component={RouterLink}
            to="/contact"
            underline="none"
            variant="body1"
            // style={{ marginRight: '10' }}
          >
            Contact
          </Link>
          {/*<Link
            className={classes.link}
            // color="white"
            component={RouterLink}
            // to="https://blog.osneycapital.com/"
            onClick={() => {
              // console.log("click is clicking");
              // window.location.href = "https://blog.osneycapital.com/";
              window.open('https://blog.osneycapital.com/', '_blank');
            }}
            underline="none"
            variant="body1"
            // style={{ marginRight: '10' }}
          >
            Blog
          </Link>*/}
          {/*<Link
            className={classes.link}
            color="textSecondary"
            component={RouterLink}
            onClick={handleClick}
            underline="none"
            variant="body1"
          >
            <LinkedInIcon color="white" style={{ marginRight: '10' }} />
          </Link>*/}
          {/*<Link
            className={classes.link}
            color="textSecondary"
            component={RouterLink}
            onClick={handleTwitterClick}
            underline="none"
            variant="body1"
          >
            {/*<Divider  />*/}
            {/*<TwitterIcon
              color="white"
              style={{ marginRight: '10' }}
              // className={classes.divider}
            />
          </Link>*/}
          <div
            style={{ minHeight: '100% !important' }}
            className={classes.divider}
            // color="textSecondary"
            // component={RouterLink}
            // onClick={handleTwitterClick}
            // underline="none"
            // variant="body2"
          />
        </Hidden>
        <Link
          // className={classes.link}
          color="textSecondary"
          component={RouterLink}
          to="/app/account"
          underline="none"
          variant="body1"
        >
          <OrangeHollowButton copy="Login" />
        </Link>
        {/*<Link
          className={classes.link}
          // color="white"
          component={RouterLink}
          to="/app/account"
          underline="none"
          variant="body2"
          // style={{ marginRight: '10' }}
        >
          Login
        </Link>*/}
        <Hidden smUp>
          <IconButton color="inherit" onClick={onMobileNavOpen}>
            <SvgIcon fontSize="small">
              <MenuIcon />
            </SvgIcon>
          </IconButton>
        </Hidden>
        {/*
        <Link
          // className={classes.link}
          color="textSecondary"
          component={RouterLink}
          to="/app/account"
          underline="none"
          variant="body2"
        >
          <Button
            color="white"
            component="a"
            // href="/app/account"
            variant="contained"
            size="small"
            // className={classes.padding}
          >
            Get Started
          </Button>
        </Link>*/}
        {/*<Search />*/}
        {/*<Contacts />*/}
        {/*<Notifications />*/}
        {/*<Settings />*/}
        <Box ml={2}>{/*<Account />*/}</Box>
      </Toolbar>
    </AppBar>

    // <AppBar className={clsx(classes.root, className)} {...rest}>
    //   <Toolbar className={classes.toolbar}>
    //     <RouterLink to="/">
    //       <Logo className={classes.logo} style={{ marginRight: '15'
    //       ,maxHeight:'50px'
    //     }} />
    //     </RouterLink>
    //     <Hidden mdDown>
    //       <Typography
    //         variant="caption"
    //         color="white"
    //         style={{ marginLeft: '15' }}
    //       >
    //         {/*<div className={classes.version}>Version {APP_VERSION}</div>*/}
    //       </Typography>
    //     </Hidden>
    //     <Box flexGrow={1} />

    //     <Link
    //       className={classes.link}
    //       // color="white"
    //       component={RouterLink}
    //       to="/philosophy"
    //       underline="none"
    //       variant="body2"
    //       // style={{ marginRight: '10' }}
    //     >
    //       Philosophy
    //     </Link>
    //     <Link
    //       className={classes.link}
    //       // color="white"
    //       component={RouterLink}
    //       to="/team"
    //       underline="none"
    //       variant="body2"
    //       // style={{ marginRight: '10' }}
    //     >
    //       Team
    //     </Link>
    //     <Link
    //       className={classes.link}
    //       // color="white"
    //       component={RouterLink}
    //       // to="https://blog.osneycapital.com/"
    //       onClick={() => {
    //         console.log("click is clicking");
    //         window.location.href = "https://blog.osneycapital.com/";
    //       }}
    //       underline="none"
    //       variant="body2"
    //       // style={{ marginRight: '10' }}
    //     >
    //       Blog
    //     </Link>
    //     <Link
    //       className={classes.link}
    //       color="textSecondary"
    //       component={RouterLink}
    //       onClick={handleClick}
    //       underline="none"
    //       variant="body2"
    //     >
    //       <LinkedInIcon color="white" style={{ marginRight: '10' }} />
    //     </Link>
    //     <Link
    //       className={classes.link}
    //       color="textSecondary"
    //       component={RouterLink}
    //       onClick={handleTwitterClick}
    //       underline="none"
    //       variant="body2"
    //     >
    //       {/*<Divider  />*/}
    //       <TwitterIcon
    //         color="white"
    //         style={{ marginRight: '10' }}
    //         // className={classes.divider}
    //       />
    //     </Link>
    //     <div
    //       style={{ minHeight: '100% !important' }}
    //       className={classes.divider}
    //       // color="textSecondary"
    //       // component={RouterLink}
    //       // onClick={handleTwitterClick}
    //       // underline="none"
    //       // variant="body2"
    //     />

    //     <Link
    //       // className={classes.link}
    //       color="textSecondary"
    //       component={RouterLink}
    //       to="/app/account"
    //       underline="none"
    //       variant="body2"
    //     >
    //       <Button
    //         color="white"
    //         component="a"
    //         // href="/app/account"
    //         variant="contained"
    //         size="small"
    //         // className={classes.padding}
    //       >
    //         Get Started
    //       </Button>
    //     </Link>
    //   </Toolbar>
    // </AppBar>
  );
};

TopBar.propTypes = {
  className: PropTypes.string,
  onMobileNavOpen: PropTypes.func
};

TopBar.defaultProps = {
  onMobileNavOpen: () => {}
};

export default TopBar;
